<template>
  <div class="email">
    <div v-if="!isEms">
      <div class="tit">{{ $t('邮箱验证') }}</div>
      <div class="tip">
        {{ $t('reg_emailVerify_1') }}
      </div>
      <el-form
        :model="ruleForm"
        label-width="120px"
        ref="ruleForm"
        label-position="left"
        :hide-required-asterisk="true"
        size="small"
        class="demo-ruleForm"
      >
        <el-form-item
          :label="$t('reg_forgetPassword_3')"
          :rules="[
            {
              required: true,
              message: $t('reg_forgetPassword_4'),
              trigger: 'blur',
            },
            {
              type: 'email',
              message: $t('reg_forgetPassword_5'),
              trigger: ['blur'],
            },
          ]"
          prop="email"
        >
          <el-input
            v-model="ruleForm.email"
            :placeholder="$t('reg_forgetPassword_6')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="textCenter">
        <el-button @click="emsSend" :loading="nextLoading" type="primary">
          {{ $t('reg_forgetPassword_7') }}
        </el-button>
        <el-button @click="back" type="text">{{
          $t('reg_forgetPassword_8')
        }}</el-button>
      </div>
    </div>
    <div class="emsOk" v-else>
      <i class="el-icon-success"></i>
      <div class="emsTip">
        {{ $t('reg_forgetPassword_9') }}{{ ruleForm.email }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Email',
  data() {
    return {
      ruleForm: {
        email: '',
        event: 'code',
      },
      isEms: false,
      nextLoading: false,
    }
  },
  methods: {
    back() {
      this.$router.back(-1)
    },
    toLogin() {
      this.$router.push({ name: 'Login' })
    },
    emsSend() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.nextLoading = true
          let res = await this.$axios.post('/api/Ems/sendEmail', this.ruleForm)
          this.nextLoading = false
          if (res.code === 1) {
            this.isEms = true
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.email {
  width: 500px;
  margin: 50px auto 0;
  .tit {
    font-size: 36px;
    margin-bottom: 50px;
    text-align: center;
  }
  .tip {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .demo-ruleForm {
    width: 500px;
    margin-top: 30px;
  }
  .textCenter {
    margin-top: 50px;
    .el-button {
      width: 100%;
      margin-left: 0;
    }
  }
  .emsOk {
    text-align: center;
    margin-top: 120px;
    i {
      font-size: 60px;
      color: #409eff;
    }
    .emsTip {
      font-size: 14px;
      margin-top: 30px;
    }
  }
}
</style>
