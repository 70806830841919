var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email"},[(!_vm.isEms)?_c('div',[_c('div',{staticClass:"tit"},[_vm._v(_vm._s(_vm.$t('邮箱验证')))]),_c('div',{staticClass:"tip"},[_vm._v(" "+_vm._s(_vm.$t('reg_emailVerify_1'))+" ")]),_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"label-width":"120px","label-position":"left","hide-required-asterisk":true,"size":"small"}},[_c('el-form-item',{attrs:{"label":_vm.$t('reg_forgetPassword_3'),"rules":[
          {
            required: true,
            message: _vm.$t('reg_forgetPassword_4'),
            trigger: 'blur',
          },
          {
            type: 'email',
            message: _vm.$t('reg_forgetPassword_5'),
            trigger: ['blur'],
          } ],"prop":"email"}},[_c('el-input',{attrs:{"placeholder":_vm.$t('reg_forgetPassword_6')},model:{value:(_vm.ruleForm.email),callback:function ($$v) {_vm.$set(_vm.ruleForm, "email", $$v)},expression:"ruleForm.email"}})],1)],1),_c('div',{staticClass:"textCenter"},[_c('el-button',{attrs:{"loading":_vm.nextLoading,"type":"primary"},on:{"click":_vm.emsSend}},[_vm._v(" "+_vm._s(_vm.$t('reg_forgetPassword_7'))+" ")]),_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.back}},[_vm._v(_vm._s(_vm.$t('reg_forgetPassword_8')))])],1)],1):_c('div',{staticClass:"emsOk"},[_c('i',{staticClass:"el-icon-success"}),_c('div',{staticClass:"emsTip"},[_vm._v(" "+_vm._s(_vm.$t('reg_forgetPassword_9'))+_vm._s(_vm.ruleForm.email)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }